import crudGetters from "@/store/templates/crud/getters";

export default {
  buddy: state => state.model,
  id: state => state.model.id,
  firstName: state => state.model.firstName,
  lastName: state => state.model.lastName,
  user: state => state.user[state.model.user],
  email: state => {
    if (state.model.id !== null) {
      return state.user[state.model.user].email;
    } else {
      return state.model.user.email;
    }
  },
  newsletter: state => state.model.newsletter,
  submissions: state => state.model.submissions,
  fullName: state => {
    return `${state.model.firstName || ""} ${state.model.lastName || ""}`;
  },
  ...crudGetters
};
